<template>
   <modal class="memberPaymentModel" :isShow="isShow" data-v-6ff9262e>
        <div class="header">会员支付<span class="close" @click="hide()">×</span></div>
        <div class="modal-body" >
            <div ref="CardMoney">
                <div class="filter-nav">
                    <div class="nav-box">
                        <div class="nav-li selected">按会员卡号支付</div>
                    </div>
                </div>
                <div class="flex-box" >
                    <div class="left-box">
                        <div>
                            <div class="tr-li">
                                请扫码或输入付款码：
                                <div class="from-input" style="width:325px">
                                    <input type="text" ref="input" class="key-focus" data-model='idCode' @focus="(e)=>e.target.select()"  v-model="idCode" @keyup.enter="onMemberInfo()" />
                                    <i class="iconfont icon-sousuo" @click="onMemberInfo()"></i>
                                </div>
                                <button class="btn" @click="readCard()">读卡</button>
                            </div>
                            <div class="table-box">
                                <div class="tr-li">
                                    <div class="lable-txt" style="width:220px;">会员卡号：<span style="color: #000;font-size: 14px;">{{meberInfo?.Card_No}}</span></div>
                                    <div class="lable-txt" style="width:200px;">会员等级：<span style="color: #000;font-size: 14px;">{{meberInfo?.Bestech_Card_KindName}}</span></div>
                                    <div class="lable-txt" style="width:145px;">姓名：<span style="color: #000;font-size: 14px;">{{meberInfo?.MemberName}}</span></div>
                                </div>
                                <div class="tr-li">
                                    <div class="lable-txt" style="width:220px;">
                                        <span style="letter-spacing:0.5em;">手机</span>号:
                                        <span style="color: #000;font-size: 14px;">{{meberInfo?.Mobile}}</span>
                                    </div>
                                    <div class="lable-txt" style="width:200px;">
                                        性<span style="padding-left:25px;">别</span>：
                                        <span style="color: #000;font-size: 14px;" v-if="meberInfo?.Gender>-1">{{meberInfo?.Gender==0?'男':'女'}}</span>
                                    </div>
                                    <div class="lable-txt" style="width:145px;">生日：<span style="color: #000;font-size: 14px;">{{meberInfo?.Birthday}}</span></div>
                                </div>
                                <div class="tr-li">
                                    <div class="lable-txt" style="width:220px;">
                                        余<span style="padding-left:25px;">额</span>:
                                        <span style="color: red;font-size: 17px;font-weight:600;" v-if="meberInfo?.Card_Money>0" >{{meberInfo?.Card_Money}}</span>
                                        <span style="color: red;"  v-if="meberInfo?.Gift_Money>0" >（赠{{meberInfo?.Gift_Money}}）</span>
                                    </div>
                                    <div class="lable-txt" style="width:200px;">
                                        <span style="letter-spacing:0.6em;">可用</span>券：
                                        <span style="color: red;font-size: 17px;font-weight:600;">{{meberInfo?.Live_CouponsNum}}</span>
                                        <span style="color: red;padding-left:5px;" v-if="meberInfo?.Live_CouponsNum">张</span>
                                    </div>
                                    <div class="lable-txt" style="width:145px;">积分：
                                        <span style="color: red;font-size: 17px;font-weight:600;"  v-if="meberInfo?.Card_PilePoint>0">{{meberInfo?.Card_PilePoint}}</span>
                                    </div>
                                </div>
                            </div>

                            <div class="pour">注:剩余可开票金额：
                                <span v-if="meberInfo?.InvoiceMoney_Remain>0">{{meberInfo?.InvoiceMoney_Remain}}</span>
                                <span v-else>0</span>
                            </div>
                            <div class="money-info">
                                <div class="lable-txt">待支付金额：
                                    <b class="noney red">{{data?.payMoney}}</b>
                                </div>
                                <!-- <div class="lable-txt">储值支付：
                                    <input type="text" class="key-focus" style="color:red;" ref="couponsPayinput" data-model='couponsPay' v-model="couponsPay" @click="onCouponPayClick()" />
                                </div> -->
                            </div>
                        </div>
                    </div>
                    <div class="right-box">
                        <div class="keyboard">
                            <div class="key-li"><span data-value="7">7</span><span data-value="8">8</span><span
                                    data-value="9">9</span></div>
                            <div class="key-li"><span data-value="4">4</span><span data-value="5">5</span><span
                                    data-value="6">6</span></div>
                            <div class="key-li"><span data-value="1">1</span><span data-value="2">2</span><span
                                    data-value="3">3</span></div>
                            <div class="key-li"><span data-value=".">.</span><span data-value="0">0</span><span
                                    data-value="back"><i class="iconfont icon-zuojiantou"></i></span></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-box">
            <button class="btn btn-cancel" @click="hide()">取消</button>
            <button class="btn btn-border" @click="quchongzhi()" >去充值</button>
            <button class="btn btn-confirm" @click="confirm()">确认</button>
        </div>

        <!--会员卡信息-->
        <modal-load :isShow="cardMemberShow">
            <card-member-Model :isShow="cardMemberShow" v-on:closeModel="cardMemberShow=false" :data="cardMemberList" @confirm="selectCardInfo" ></card-member-Model>
        </modal-load>
    </modal>
</template>


<script>
import cardMemberModel from '/src/components/home/diningTable/model/cardMemberModel/cardMemberModel.vue'
import {initKeyBoard} from '/src/common'
import { newGuid } from '/src/common/index.js'

/**会员支付 */
export default {
    name:"memberPaymentModel",
    emits:["close","success","updateTime"],
    components:{
        cardMemberModel,
    },
    props:{
        isShow:Boolean,
        /**
         * {
         *  payMoney:待支付金额,
         * }
         */
        data:{
            typeof:Object,
            default:null,
        },
    },
    data(){
        return {
            idCode:'',//身份码
            tempidCode:"",//临时 记录上一次身份码
            meberInfo:null, //会员信息
            couponsPay:'', //储值支付金额
            /**是否触发过会员查询 */
            isGetMember:false,

            /**卡id */
            card_AutoID:'',
            /**内卡号 */
            cardSNR:'',
            /** 会员选择弹层 */
            cardMemberShow:false,
            cardMemberList:[], //会员卡信息 多条
            /**付款记录id 用于支付查询 */
            Biz_ID:null,
        }
    },
    mounted(){
       this.$nextTick(()=> {
            initKeyBoard.call(this,this.$refs.CardMoney);
            this.EmptyDatas();
        })
    },
    watch: {
        //用于观察数据变动。对应一个对象,键是观察表达式,值是对应回调。子组件中 需要结合watch监听
        isShow() {
            if(this.isShow){
                this.init()
            }
        },
        couponsPay(){
            if(this.couponsPay<0){
               this.couponsPay=0
            }
        },
    },
    methods:{
        hide(){
            this.$emit("close")
        },
        /**数据初始化 */
        init(){
            this.idCode='';
            this.tempidCode='';
            this.meberInfo=null;
            this.couponsPay='';
            this.isGetMember=false;
            this.card_AutoID='';
            this.cardSNR='';
            this.cardMemberShow=false;
            this.cardMemberList=[];
            this.Biz_ID=null;
            this.EmptyDatas();
        },
        /**跳转到会员充值界面 */
        quchongzhi(){
            this.$router.push({name: 'member',params:{memberPay:1}});
        },
        /**默认input焦点 */
        EmptyDatas(){
            setTimeout(() => {
                this.$refs.input?.focus();
            },100);
        },
        /**实体卡读卡 */
        readCard(){
            this.$webBrowser.redCardM1().then((d)=>{
                if(d){
                    if(d.state==0){
                        this.card_AutoID=d.data.cardID;
                        this.idCode=d.data.cardNo;
                        this.cardSNR=d.data.snr
                        this.onMemberInfo()
                    }else{
                        this.$message.error('卡信息读取失败：'+d.message);
                    }
                }
            })
        },
        /**获取会员信息 */
        onMemberInfo(){
            if(!this.idCode){
                this.$message.error('查询码不能为空');
                return
            }
            this.idCode =this.idCode.replace(/[;,；,=,＝,?,？]/g,"");
            //this.$refs.input.blur();
            this.$refs.input?.select();
            if(this.isGetMember){
                if(this.tempidCode!=this.idCode){
                    this.idCode = this.tempidCode
                }
                return
            }
            this.isGetMember=true;
            if(!this.tempidCode){
                this.tempidCode=this.idCode
            }
            let userInfo=this.$auth.getUserInfo(); //获取用户id
            this.meberInfo=null;
            this.couponsPay="";

            let param={
                Body_ID:newGuid(),
                User_ID:userInfo?.User_ID,  //用户id
                DynamicNo:this.idCode,
                Card_AutoID:this.card_AutoID,//卡ID
                Card_SN:this.cardSNR,//内卡号
                IsGetCouponUsingRules:true,
                Need_CheckBillMoney:true,/**是否在读卡时核算账单消费优惠金额 */
                OrderConsumes:[], //核算账单优惠时，请求时传入账单菜品明细
            }
            const loading = this.$loading({
                text: "加载中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.1)",
            });
            this.$httpAES.post("Bestech.CloudPos.GetMemberInfo",param).then((data)=>{
                loading.close();
                this.isGetMember=false
                this.tempidCode=''
                if(data.ResponseHeader.ResultCode!=0){
                    this.$message.error(data.ResponseHeader.ResultDesc);
                    this.idCode=''
                }else{
                    this.cardSNR=''
                    this.card_AutoID=''
                    if(data.ResponseBody.length>1){
                        this.cardMemberList = data.ResponseBody;
                        this.cardMemberShow=true;
                    }else{
                        this.isread=true
                        this.meberInfo=data.ResponseBody[0];
                        if(this.meberInfo.OverDraftLimitedMoney){
                            this.meberInfo.Card_Money = Number(this.meberInfo.Card_Money) + Number(this.meberInfo.OverDraftLimitedMoney)
                        }
                        this.cardInfoShow()
                        this.$emit('memberCardBack',this.meberInfo)
                    }
                }
            }).catch((e)=>{
                loading.close();
                this.isGetMember=false
                this.$alert(e.message, "提示", {confirmButtonText: "确定"});
            })
        },
        /**确定付款 */
        confirm(){
            if(!this.meberInfo){
                this.$message.warning('请先读取会员卡!');
                return
            }
            // if(this.couponsPay>0){
            //     if(Number(this.couponsPay)>this.data?.payMoney){
            //         this.$message.error('储值支付金额超过待支付金额!');
            //         return
            //     }
            // }else{
            //     this.$message.error('请输入储值支付金额!');
            //     return
            // }

            if(Number(this.data?.payMoney)>Number(this.meberInfo.Card_Money)){
                this.$message.error('卡内余额不足,本次支付所需'+this.data?.payMoney+'，卡内余额：'+this.meberInfo.Card_Money);
                return
            }


            let userInfo=this.$auth.getUserInfo(); //获取用户id
            let param={
                User_ID:userInfo.User_ID,
                Operator_Name:userInfo?.Login_Name,
                Corp_AutoID:this.data?.Corp_AutoID,//原账户ID
                Pay_ID:this.data?.Pay_ID,//支付方式id
                PaymentType:"801010401",//正品CRM3.0会员支付
                Card_AutoID:this.meberInfo.Bestech_Card_AutoID,//卡主键
                Card_No:this.meberInfo.Card_No,//卡编号
                Corp_SumMoney:this.data?.money,//总金额
                Pay_Money:this.data.payMoney,//支付金额
                Pay_Type:this.data.payType,//0：部分清账 1：全部清账
                InvoiceStatus:this.data.InvoiceStatus,//0 未开票  1已开票
                Remark:this.data.remark,
                Pos_StationName:userInfo?.Site_Name,//站点名称
                Operate_EquipmentID:userInfo?.Site_Serial, //设备id
                Recharge_Type:this.data.Recharge_Type,//0：来款清账 1：预付款充值
                LastUpdated_Time:this.data?.LastUpdated_Time,//原账户最后更新时间
                Corp_ConsumeIDList:this.data.Corp_ConsumeIDList,//明细主键List
            }

            /**支付预览 */
            const loading = this.$loading({
                text: "支付预览中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.1)",
            });
            this.$httpAES.post("Bestech.CloudPos.PayARCorpPreview",param).then((data)=>{
                loading.close();
                if(data.ResponseHeader.ResultCode!=0){
                    this.$message.error(data.ResponseHeader.ResultDesc||"付款失败");
                }else{
                    this.Biz_ID=data.ResponseBody?.Biz_ID;
                    if(data.ResponseBody?.OpenCashBox==true){
                        this.$webBrowser.openCashBoxBnt().then(d=>{
                            if(d?.state!=0){
                                this.$message.error('钱箱打开失败:'+(d?.message||""));
                            }
                        });
                    }
                    this.PaySubmit(data.ResponseBody);//提交

                    /**支付提交判断 0不需要 1需要 */
                    // if(data.ResponseBody.Is_NeedSubmit==1){
                    //     this.PaySubmit(data)
                    // }else{
                    //     if(data.ResponseBody?.PrintsInfo){
                    //         setTimeout(()=>{
                    //             this.$webBrowser.posPrint(data.ResponseBody.PrintsInfo);
                    //         },100);
                    //     }
                    //     this.$message.success({ message: "支付完成"});
                    //     this.$emit('paySuccessConfirm');
                    // }
                }
            }).catch((e)=>{
                loading.close();
                this.$alert(e.message, "提示", {confirmButtonText: "确定"});
            })
        },
        /**支付提交方法 */
        PaySubmit(data){
            if(data?.Biz_ID){
                let userInfo=this.$auth.getUserInfo(); //获取用户id
                let param={
                    User_ID:userInfo?.User_ID,  //用户id
                    Biz_ID:data?.Biz_ID, //付款记录id
                    Operator_Name:userInfo?.Login_Name, //操作员
                    Operate_EquipmentID:userInfo?.Site_Serial, //设备id
                    Pos_StationName:userInfo?.Site_Name, //站点名称
                }
                /**支付提交 */
                const loading = this.$loading({
                    text: "支付提交中...",
                    spinner: "el-icon-loading",
                    background: "rgba(0, 0, 0, 0.1)",
                });
                console.log(param)
                this.$httpAES.post("Bestech.CloudPos.PayARCorpSubmit",param).then((data)=>{
                    loading.close();
                    if(data.ResponseHeader.ResultCode!=0){
                        this.$message.error(data.ResponseHeader.ResultDesc);
                    }else{
                        //更新时间戳
                        if(data.ResponseBody?.Corp_LastUpdateTime){
                            this.$emit("updateTime",data.ResponseBody?.Corp_LastUpdateTime);
                        }
                        if(this.data.Recharge_Type==0){
                            this.$message.success("清账成功");
                        }else{
                            this.$message.success("付款成功");
                        }

                        this.$emit("success");
                        if(data.ResponseBody?.OpenCashBox==true){
                            this.$webBrowser.openCashBoxBnt().then(d=>{
                                if(d?.state!=0){
                                    this.$message.error('钱箱打开失败:'+(d?.message||""));
                                }
                            });
                        }
                        if(data.ResponseBody?.PrintsInfo){//打印
                            setTimeout(()=>{
                                this.$webBrowser.posPrint(data.ResponseBody.PrintsInfo);
                            },100);
                        }
                    }
                }).catch((e)=>{
                    loading.close();
                    this.$alert(e.message, "提示", {confirmButtonText: "确定"});
                })
            }
        },


        /**会员卡选择返回 */
        selectCardInfo(data){
            this.meberInfo=data;
            if(this.meberInfo.OverDraftLimitedMoney){
                this.meberInfo.Card_Money = Number(this.meberInfo.Card_Money) + Number(this.meberInfo.OverDraftLimitedMoney)
            }
            this.idCode = data.Card_No;
            this.cardMemberShow = false;
            this.$emit('memberCardBack',this.meberInfo)
            this.cardInfoShow()
        },
        /**会员卡信息展示 */
        cardInfoShow(){
            if(this.data?.payMoney< Number(this.meberInfo.Card_Money)){
                this.couponsPay = this.data?.payMoney;
            }else{
                this.couponsPay = Number(this.meberInfo.Card_Money)
            }
        }
    }
}
</script>

<style lang="sass">
@import "./memberPaymentModel.scss";
</style>